.ReactVirtualized__Table__headerRow {
  cursor: default !important;
}

.ReactVirtualized__Table__sortableHeaderColumn {
  cursor: pointer;
}

.clickable div.preventHoverStyle:hover {
  cursor: default;
  background-color: #fff;
}

.clickable .ReactVirtualized__Table__row:hover {
  cursor: pointer;
  background-color: #fbfbfb;
}

.ReactVirtualized__Table__row .ReactVirtualized__Table__rowColumn .map-link {
  display: none !important;
}

.ReactVirtualized__Table__row:hover .ReactVirtualized__Table__rowColumn .map-link {
  display: flex !important;
}
/*# sourceMappingURL=index.96f5add7.css.map */
